import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, Text, Title } from '@mantine/core';

export function ContactUs () {
    return (
        <>
            <HeaderSimple />
            <Box mt={80} bg={"#2d3147"} w={"100%"} py={60} h={300} >
                <Group align='center' justify='center' h={"100%"}>
                    <Title order={1} c={"#fff"}>اتصل بنا</Title>
                </Group>
            </Box>
            <Container size={"sm"}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/contact-us.jpg"} alt='shipping-delivery' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text>
                        نحن دائماً 
                        <Text span fw={'bold'}> جاهزون لمساعدتك! </Text>
                        .. سنكون سعداء بالتواصل معك والرد على تساؤلاتك، تواصل معنا من خلال أي من قنوات الدعم التالية :
                    </Text>
                    <Text>هاتف / 0655155723</Text>
                    <Text>
                        البريد الإلكتروني / 
                        <Text span fw={'bold'}> contact@example.com </Text>
                    </Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}