import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, Text, Title } from '@mantine/core';

export function PaymentMethods () {
    return (
        <>
            <HeaderSimple />
            <Box mt={80} bg={"#2d3147"} w={"100%"} py={60} h={300} >
                <Group align='center' justify='center' h={"100%"}>
                    <Title order={1} c={"#fff"}>طرق الدفع</Title>
                </Group>
            </Box>
            <Container size={"sm"}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/cod.jpg"} alt='shipping-delivery' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text fw={'bold'}> الدفع نقداً عند الاستلام (COD)</Text>
                    <Text>الدفع عند الاستلام (Cash On Delivery) هي الطريقة الوحيدة حالياً المتاحة على متجرنا، والدفع عند الاستلام يعني أن المتسوق يمكنه التسوق عبر متجرنا إلكتروني واختيار المنتج الذي يرغب فيه ومن ثم يقوم بإجراء الطلب، وهو ما يعني أن عملية الدفع تؤجل حتى استلام العميل للمنتج الذي قام بطلبه إلكترونيًا.</Text>
                    <Text>وسنقوم بإرسال المنتج إلى المكان المتفق عليه (المدينة أو الحي أو المنزل، مكان العمل أو مكان اخر)، وبعدها يتم الدفع.</Text>
                    <Text>
                        يمكنك الطلب مباشرة 
                        <Text span fw={'bold'}> عبر الهاتف </Text>
                        : 0655155723
                    </Text>

                    <Text fw={'bold'}>ملاحظة :</Text>
                    <Text>يرجى إبقاء هاتفك مفتوح حتى يتمكن العون المكلف بالتوصيل بالإتصال بك عندما يكون قريب من العنوان.</Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}