import React, { useEffect } from 'react';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { Box, Container, Group, Image, Text, Title } from '@mantine/core';

export function ShippingAndHandling () {
    return (
        <>
            <HeaderSimple />
            <Box mt={80} bg={"#2d3147"} w={"100%"} py={60} h={300} >
                <Group align='center' justify='center' h={"100%"}>
                    <Title order={1} c={"#fff"}>الشحن والتسليم</Title>
                </Group>
            </Box>
            <Container size={"sm"}>
                <Group align='center' justify='center' w={"100%"}>
                    <Image src={"/shipping-delivery.jpg"} alt='shipping-delivery' w={"100%"} />
                </Group>
                <Group align='flex-start' justify='center' w={"100%"} opacity={0.9} style={{flexDirection: "column"}}>
                    <Text>بعد تأكيد عملية الشراء هاتفياً، نقوم بشحن وإرسال المنتج عبر خدمات التوصيل المتاحة .</Text>
                    <Text fw={'bold'}>طرق الشحن المتوفرة :</Text>
                    <Text>
                        شركة توصيل خاصة خدمة سريعة تضمن لكم تسليم الطرود إلى العنوان المطلوب في مدة تتراوح بين
                        <Text span fw={'bold'}> يوم واحد </Text>
                        إلى 
                        <Text span fw={'bold'}> 3 أيام </Text>
                        ، على حسب قرب وبعد المنطقة عن عاصمة الولاية.
                    </Text>
                </Group>
            </Container>
            <Footer/>
        </>
    );
}