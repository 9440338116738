import { Text, Container, Group, Image, useMantineTheme, Title, SimpleGrid, Box, ActionIcon, rem, Card, Overlay, Button, Grid, Spoiler } from '@mantine/core';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import axios from 'axios';
import classes from './../../styles/CategoriesSection.module.css';
import useStore from '../../store/useStore';
import { CategoryCard } from '../Cards';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';



export function CategoriesSection() {
    const matches = useMediaQuery('(max-width: 36em)');
    let [allCategories, setAllCategories] = useState<any[]>([]);
    const dataStore = useStore((state: any) => state.store);
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/category`)
        .then(({data}) => {
            setAllCategories(data)
        })
        .catch((error) => console.log(error));
    }, [])

    return (
        <section style={{marginTop: dataStore?.topBar && dataStore?.topBar?.show && dataStore?.topBar?.content ? 140 : 100}}>
            {searchParams.get("search") && searchParams.get("search") !== ""
                ? <></>
                : <Container size={'lg'}>
                    <Title 
                        order={2} className={classes.title} ta="center" mt="sm"
                        
                        style={{
                            borderBottom: `6px solid ${dataStore?.information?.backgroundColor || "#48a172"}99`
                        }}
                    >
                        تصنيفات المتجر
                    </Title>
            
                    <Text mb={20} c="dimmed" className={classes.description} ta="center" mt="md" fw={"bold"}>
                        نتوفر على جميع المنتوجات التي ستحتاجها
                    </Text>
                    {allCategories.length > 0
                        ? <Grid gutter={20}>
                            {allCategories?.slice(0, 3)?.map((item: any, index: number) => (
                                <Grid.Col span={{base: 12, sm: index === 0 ? 12 : 6}} key={index}>
                                    <CategoryCard
                                        title={item?.name}
                                        background={item?.image}
                                        isFirst={index === 0}
                                    />
                                </Grid.Col>
                            ))}
                        </Grid>
                        : null
                    }

        
                    {allCategories.length > 3
                        ? <Spoiler 
                            my={'sm'} maxHeight={0} mb={100}
                            showLabel={
                                <Group justify='center' align='center' w={"100%"}>
                                    <Button radius={'xl'} variant="filled" color={dataStore?.information?.backgroundColor || "#48a172"} size="xs" rightSection={<IconChevronDown size={16} />}>
                                        المزيد من الاصناف
                                    </Button>
                                </Group>
                            }
                            hideLabel={
                                <Group justify='center' align='center' w={"100%"} mt={20}>
                                    <Button radius={'xl'} variant="filled" color={dataStore?.information?.backgroundColor || "#48a172"} size="xs" rightSection={<IconChevronUp size={16} />}>
                                        عرض اقل 
                                    </Button>
                                </Group>
                            }
                            classNames= {{
                                control: classes.btnSpoiler
                            }}
                        >
                            <Grid>
                                {allCategories?.slice(3, allCategories.length)?.map((item: any, index: number) => (
                                    <Grid.Col  span={{base: 12, sm: 6}} key={index}>
                                        <CategoryCard
                                            title={item?.name}
                                            background={item?.image}
                                            isFirst={false}
                                        />
                                    </Grid.Col>
                                ))}
                            </Grid>
                        </Spoiler>
                        : null
                    }
                </Container>
            }
        </section>
    );
}